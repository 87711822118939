import '@assets/scss/pages/project.scss';

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// components
import Layout from '@src/layouts';
import Video from '@components/atoms/Video';
// import Video from '@components/atoms/Video';
import Blockquote from '@components/molecules/Blockquote';
// import ButtonExternal from '@components/atoms/buttons/ButtonExternal';
// import ButtonSecondary from '@components/atoms/buttons/ButtonSecondary';
import Column from '@components/organisms/Column';
import Gallery from '@components/organisms/Gallery';
// import Cta from '@components/molecules/Cta';
// import Gallery from '@components/organisms/Gallery';
import HeroProject from '@components/molecules/heros/HeroProject';
import Image from '@components/atoms/Image';
// import ItemArticle from '@components/molecules/items/ItemArticle';
import ListText from '@components/organisms/ListText';

// --------------------------------
// #region data
// --------------------------------

const language = 'en';

const pageClass = 'page-project';

const accent_color = '#FFF';
const footerRelatedLinks = [
	{
		title: 'Avrion Therapeutics',
		url: '/en/projects/avrion-therapeutics-branding/',
	},
];

// --------------------------------
// #endregion
// --------------------------------

const ProjectMjfPage = (props) => {
	const data = useStaticQuery(
		graphql`
			query {
				heroImage: file(
					relativePath: {
						eq: "projects/mjf-swipe/mjf_swipe_hero.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 2560, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				heroImageMobile: file(
					relativePath: {
						eq: "projects/mjf-swipe/mjf_swipe_hero_mobile.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 2560, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				mjfswipe_3: file(
					relativePath: { eq: "projects/mjf-swipe/70K_en.png" }
				) {
					childImageSharp {
						fluid(maxWidth: 660, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				mjfswipe_4: file(
					relativePath: { eq: "projects/mjf-swipe/100K_en.png" }
				) {
					childImageSharp {
						fluid(maxWidth: 660, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	);

	return (
		<Layout
			language={language}
			pageClass={pageClass}
			footerRelatedLinks={footerRelatedLinks}
			accentColor={accent_color}
			location={props.location}
			translations={[
				{
					uri: '/fr/projets/montreux-jazz-festival-swipe-app/',
					lang: 'fr',
				},
			]}
			title="Montreux Jazz Festival Swipe App - By Superhuit"
		>
			<HeroProject
				breadcrumb={{
					title: 'Work',
					link: '/en/projects/',
				}}
				title="Montreux Jazz Festival – Swipe App"
				image={{
					...data.heroImage.childImageSharp,
				}}
				imageMobile={{
					...data.heroImageMobile.childImageSharp,
				}}
			/>
			<section className="grid grid-content" aria-label="Project">
				<div className="project-intro" data-animation-page>
					<div className="project-summary text-content">
						<p className="p-medium">
							Create your own festival program according to your
							tastes and in just a few swipes.
						</p>

						<p className="p-small">
							With its reputation for showcasing the greatest
							artists of all time, the Montreux Jazz Festival is a
							one-of-a-kind event that has been celebrating live
							music since 1967 on the shores of Lake Geneva.
						</p>

						<div className="list-two-columns">
							<ListText
								className="element-spacing"
								title="Our services"
								list={[
									'Strategy workshops',
									'Information architecture',
									'Data design',
									'Art direction',
									'Web development',
									'Analytics',
								]}
							/>
							<ListText
								title="Awards"
								list={[
									'Site of the Day - Awwwards',
									'Developer of the Day - Awwwards',
									'Site of the Day - CSSDA',
								]}
							/>
						</div>
					</div>
					<div className="project-description text-content">
						<h3>The challenge</h3>
						<p>
							With a packed program and an ever-increasing number
							of concerts, the audience struggles to easily
							identify the artists that suit their musical
							preferences.
						</p>
						<h3>Our solution</h3>
						<p>
							We created a fun web app that allows users to
							explore the program in a more personalized and
							accessible way. The result? Nearly 100,000
							personalized programs were created in just a few
							weeks, with an experience completion rate of over
							70%. The experience enabled festival-goers to
							discover new artists they’d never heard of.
						</p>
						{/*
						<div className="project-links text-content">
							<ButtonExternal
								href="https://www.mjfswipe.com/swipeit/"
								text="Découvrir la web-app"
							/>
						</div>*/}
					</div>
				</div>

				<div className="box" data-animation-page>
					<div className="list-two-columns align-items-center justify-normal">
						<div className="text-content">
							<Image
								{...data.mjfswipe_4.childImageSharp}
								alt=""
							/>
						</div>
						<Image {...data.mjfswipe_3.childImageSharp} alt="" />
					</div>
				</div>

				{/***** 1 COLUMN VIDEO *****/}
				<Video
					className="col-full"
					fluid={[
						{
							src: '6e43c600ffe904defae623b1b9850a69',
						},
						{
							src: '6005d1515a9b2d2653e6c85510dc5af5',
							minWidth: 768,
						},
					]}
					alt=""
				/>

				<div className="box" data-animation-page>
					<div className="list-two-columns align-items-top justify-normal">
						<div className="text-content">
							<h3>
								A powerful, fun and completely immersive
								experience.
							</h3>
						</div>
						<div className="text-content">
							<p>
								Just a few questions stand between you and
								creating a customized program that suits your
								tastes. This makes it super simple to discover
								the best concerts for you.
							</p>
						</div>
					</div>
				</div>

				{/***** 1 COLUMN VIDEO *****/}
				<Video
					className="col-full"
					fluid={[
						{
							/*Mobile*/
							src: 'd815bc9e7404669bc317db91e9e2e507',
						},
						{
							/*Desktop*/
							src: '510fbe13fb86e6812e23c8709af45298',
							minWidth: 768,
						},
					]}
					alt=""
				/>

				{/* 12 col + [Image + Image + Image + Image] + gutter */}
				<Gallery className="col-full grid-spacing" modifiers="gutter">
					<Video src="a8946adb20330f380d040601e0612f89" alt="" />
					<Video src="3b0e220bf5ef907247626763aac780c2" alt="" />
				</Gallery>

				<Blockquote
					uptitle="Customer testimonial"
					quote="“Superhuit has been a true strategic partner for the development of a new digital strategy for the Montreux Jazz Festival and its commercial universe.”"
					cite="Michelle Goff – Chief Digital Officer, Montreux Media Venture"
				/>

				<Column modifiers="left">
					<div className="column__media text-content">
						{/***** VIDEO RESPONSIVE *****/}
						<Video
							fluid={[
								{
									/*Mobile*/
									src: '0496d5a7ca09aec9c70ea6b07501afa3',
								},
								{
									/*Desktop*/
									src: '3d021f513faf13e369870c1f078af579',
									minWidth: 768,
								},
							]}
							alt=""
						/>
					</div>

					<div className="column__text column__sticky text-content">
						<h3>
							Discover your own personalized program with the best
							artists to suit your tastes.
						</h3>
						<p>
							A custom Spotify playlist is generated to help you
							get ready to experience the event at its best and
							discover new artists according to your musical
							preferences.
						</p>
					</div>
				</Column>

				{/* <Cta
					subscribe
					text="Be the first to know when we release a new project"
					cta="Subscribe"
					currentPage="Project"
				/> */}
			</section>
		</Layout>
	);
};

export default ProjectMjfPage;
